import React from 'react'
import dynamic from 'next/dynamic'
import {NextPageContext} from 'next'
import {fetchSSRData} from 'src/page-utils'

const NewHomePage = dynamic(import('../src/components/Home/NewHomePage'))
const OldHomePage = dynamic(import('../src/components/Home/OldHomePage'))

function Homepage(props: any) {
  const {pageData} = props || {}
  const {data} = pageData || {}
  const {newScheme = false} = data || {}

  if (newScheme) {
    return <NewHomePage data={data} />
  }

  return <OldHomePage data={data} />
}

export async function getServerSideProps(context: NextPageContext) {
  return fetchSSRData(context, [
    {
      key: 'data',
      url: `page/home`,
      isPrimary: true,
    },
  ])
}

export default Homepage
